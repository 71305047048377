import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

export default ({
  data: {
    prismicThanks: { data },
  },
  pageContext: { locale },
}) => (
  <Layout locale={locale}>
    <SEO title="Blogs" />
    <h1>{data.title.text}</h1>
    <div dangerouslySetInnerHTML={{ __html: data.body.html }} />
  </Layout>
)

export const query = graphql`
  query getThanks($langcode: String!) {
    prismicThanks(lang: { eq: $langcode }) {
      data {
        title {
          text
        }
        body {
          html
        }
      }
    }
  }
`
